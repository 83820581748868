




































































































































































import { useContact } from '@/composition/contact'
import i18n from '@/setup/i18n'
import SiteSection from '@/components/SiteSection.vue'
import SiteShapeTree from '@/components/SiteShapeTree.vue'
import { defineComponent, ref, computed, watch } from '@vue/composition-api'
import { address, phone, medicalCenterPhone, phone2 } from '@/app.config.json'
import { formatPhoneNumber } from '@/utils/phone'

export default defineComponent({
  name: 'ContactForm',
  components: {
    SiteSection,
    SiteShapeTree,
  },

  props: {
    to: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const form = ref<HTMLFormElement | null>(null)

    const firstNameLabel = computed(() =>
      i18n.t('contactFields.firstName.label')
    )
    const firstNamePlaceholder = computed(() =>
      i18n.t('contactFields.firstName.placeholder')
    )

    const emailLabel = computed(() => i18n.t('contactFields.email.label'))
    const emailPlaceholder = computed(() =>
      i18n.t('contactFields.email.placeholder')
    )

    const phoneLabel = computed(() => i18n.t('contactFields.phone.label'))
    const phonePlaceholder = computed(() =>
      i18n.t('contactFields.phone.placeholder')
    )

    const messageLabel = computed(() => i18n.t('contactFields.message.label'))
    const messagePlaceholder = computed(() =>
      i18n.t('contactFields.message.placeholder')
    )

    const { model, submit, isSubmitting } = useContact({ form })

    watch(
      () => props.to,
      () => {
        model.to = props.to
      },
      {
        immediate: true,
      }
    )

    return {
      form,
      model,
      submit,
      isSubmitting,
      firstNameLabel,
      firstNamePlaceholder,
      emailLabel,
      emailPlaceholder,
      phone,
      medicalCenterPhone,
      phone2,
      formattedMedicalCenterPhone: formatPhoneNumber(medicalCenterPhone),
      formattedPhone: formatPhoneNumber(phone),
      formattedPhone2: formatPhoneNumber(phone2),
      phoneLabel,
      phonePlaceholder,
      messageLabel,
      messagePlaceholder,
      address,
    }
  },
})
