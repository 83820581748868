import appConfig from '@/app.config.json'
import communique from '@/notification'
import { ContactModel, contactService } from '@/services/modules/mc/contact'
import i18n from '@/setup/i18n'
import { currentLanguage } from '@/utils/language'
import { unformatPhoneNumber } from '@/utils/phone'
import { reactive, Ref } from '@vue/composition-api'
import { useState } from './state'

export interface UseContactParams {
  form?: Ref<HTMLFormElement | null>
}

export function useContact({ form }: UseContactParams) {
  const getInitialState: () => ContactModel = () => ({
    applicationId: appConfig.api.applicationId,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
    message: '',
    languageKey: currentLanguage.value.id,
    to: '',
  })
  const model = reactive<ContactModel>(getInitialState())

  const [isSubmitting, setIsSubmitting] = useState(false)

  async function submit() {
    try {
      setIsSubmitting(true)

      await contactService.send({
        ...model,
        phoneNumber: model.phoneNumber
          ? unformatPhoneNumber(model.phoneNumber)
          : undefined,
      })

      communique.dispatch({
        variant: 'success',
        message: i18n.t('contactSendSuccess') as string,
      })

      reset()
    } catch (error) {
      communique.dispatch({
        variant: 'error',
        message: i18n.t('contactSendError') as string,
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  function reset() {
    Object.assign(model, getInitialState())
    form?.value?.reset()
  }

  return {
    model,
    isSubmitting,
    submit,
  }
}
