



















import { defineComponent, computed } from '@vue/composition-api'
import IconRest1 from '../icons/IconRest1.vue'
import IconGames from '../icons/IconGames.vue'
import IconParamedic from '../icons/IconParamedic.vue'
import IconDining from '../icons/IconDining.vue'
import IconMedic from '../icons/IconMedic.vue'
import IconMenRoom from '../icons/IconMenRoom.vue'
import IconInformation from '../icons/IconInformation.vue'
import IconDetoxification from '../icons/IconDetoxification.vue'
import IconSpiritual from '../icons/IconSpiritual.vue'
import IconGym from '../icons/IconGym.vue'
import IconOrchard from '../icons/IconOrchard.vue'
// import IconIndividual from '../icons/IconIndividual.vue'
import IconPsico from '../icons/IconPsico.vue'
import IconArt from '../icons/IconArt.vue'
import IconTennis from '../icons/IconTennis.vue'
import IconVoleyball from '../icons/IconVoleyball.vue'
import IconWomenRoom from '../icons/IconWomenRoom.vue'
import i18n from '@/setup/i18n'

interface IconItem {
  iconName: string
  title: string
}

export default defineComponent({
  name: 'AmenityCard',

  components: {
    'icon-rest': IconRest1,
    'icon-games': IconGames,
    'icon-paramedic': IconParamedic,
    'icon-dining': IconDining,
    'icon-medic': IconMedic,
    'icon-men-room': IconMenRoom,
    'icon-information': IconInformation,
    'icon-detoxification': IconDetoxification,
    'icon-spiritual': IconSpiritual,
    'icon-gym': IconGym,
    'icon-orchard': IconOrchard,
    'icon-psico': IconPsico,
    'icon-art': IconArt,
    'icon-tennis': IconTennis,
    'icon-volleyball': IconVoleyball,
    'icon-women-room': IconWomenRoom,
  },

  setup() {
    const icons = computed<IconItem[]>(() => [
      {
        iconName: 'icon-rest',
        title: i18n.t('amenities.restArea.name') as string,
      },
      {
        iconName: 'icon-games',
        title: i18n.t('amenities.gamesArea.name') as string,
      },
      {
        iconName: 'icon-paramedic',
        title: i18n.t('amenities.paramedicArea.name') as string,
      },
      {
        iconName: 'icon-dining',
        title: i18n.t('amenities.diningRoom.name') as string,
      },
      {
        iconName: 'icon-medic',
        title: i18n.t('amenities.medicalFacility.name') as string,
      },
      {
        iconName: 'icon-men-room',
        title: i18n.t('amenities.menRoom.name') as string,
      },
      {
        iconName: 'icon-information',
        title: i18n.t('amenities.infoArea.name') as string,
      },
      {
        iconName: 'icon-spiritual',
        title: i18n.t('amenities.spiritualArea.name') as string,
      },
      {
        iconName: 'icon-gym',
        title: i18n.t('amenities.gym.name') as string,
      },
      {
        iconName: 'icon-orchard',
        title: i18n.t('amenities.orchard.name') as string,
      },
      {
        iconName: 'icon-psico',
        title: i18n.t('amenities.psychoCinema.name') as string,
      },
      {
        iconName: 'icon-art',
        title: i18n.t('amenities.artStudio.name') as string,
      },
      {
        iconName: 'icon-tennis',
        title: i18n.t('amenities.tennis.name') as string,
      },
      {
        iconName: 'icon-volleyball',
        title: i18n.t('amenities.volleyball.name') as string,
      },
      {
        iconName: 'icon-detoxification',
        title: i18n.t('amenities.detoxification.name') as string,
      },
    ])

    return {
      icons,
    }
  },
})
