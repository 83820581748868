




import SiteSectionHero from '@/components/SiteSectionHero.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'HomeStart',

  components: {
    SiteSectionHero,
  },
})
