














// import SiteSectionHero from '@/components/SiteSectionHero.vue'
import { defineComponent } from '@vue/composition-api'
import HomeStart from '@/components/homeComponents/HomeStart.vue'
import RebuildingLives from '@/components/homeComponents/RebuildingLives.vue'
import Guide from '@/components/homeComponents/Guide.vue'
import Services from '@/components/homeComponents/Services.vue'
import Gallery from '@/components/homeComponents/Gallery.vue'
import Help from '@/components/homeComponents/Help.vue'
import ContactForm from '@/components/homeComponents/ContactForm.vue'
import SiteSectionVideo from '@/components/SiteSectionVideo.vue'

export default defineComponent({
  name: 'Home',

  components: {
    // SiteSectionHero,
    HomeStart,
    RebuildingLives,
    Guide,
    Services,
    Gallery,
    Help,
    ContactForm,
    SiteSectionVideo,
  },
})
