




























































































































import SiteSection from '@/components/SiteSection.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SiteSectionHero',

  components: {
    SiteSection,
  },
})
