




























































































































































import SiteSection from '@/components/SiteSection.vue'
import SiteShapeTree from '@/components/SiteShapeTree.vue'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'RebuildingLives',

  components: {
    SiteSection,
    SiteShapeTree,
  },

  setup() {
    const icons = ['hand', 'shield', 'badge', 'balance', 'heart']

    const moralKeys = [
      'honesty',
      'confidentiality',
      'professionalism',
      'ethics',
      'humanWarmth',
    ]

    const morals = computed(() =>
      moralKeys.map((key) => ({
        name: `morals.${key}.name`,
        bio: `morals.${key}.bio`,
      }))
    )

    return {
      icons,
      morals,
    }
  },
})
