





































































































































































import SiteSection from '@/components/SiteSection.vue'
import { defineComponent, computed } from '@vue/composition-api'
import i18n from '@/setup/i18n'

interface InterventionItem {
  key: string
  name: string
}

export default defineComponent({
  name: 'Help',
  components: {
    SiteSection,
  },
  setup() {
    const interventions = computed<InterventionItem[]>(() => [
      { key: '1', name: i18n.t('interventions.detoxification') as string },
      { key: '2', name: i18n.t('interventions.treatment') as string },
      { key: '3', name: i18n.t('interventions.individual') as string },
      { key: '4', name: i18n.t('interventions.group') as string },
      { key: '5', name: i18n.t('interventions.neuro') as string },
      { key: '6', name: i18n.t('interventions.family') as string },
      { key: '7', name: i18n.t('interventions.training') as string },
      { key: '8', name: i18n.t('interventions.psychoeducation') as string },
      { key: '9', name: i18n.t('interventions.fisical') as string },
      { key: '10', name: i18n.t('interventions.nutritional') as string },
      { key: '11', name: i18n.t('interventions.activities') as string },
    ])

    return {
      interventions,
    }
  },
})
