
















































































































































































import SiteSection from '@/components/SiteSection.vue'
import SiteShapeTree from '@/components/SiteShapeTree.vue'
import AmenityCard from '@/components/homeComponents/AmenityCard.vue'
// import { amenities } from '@/utils/amenities'
// import { Swiper } from 'vue-awesome-swiper'
import { defineComponent } from '@vue/composition-api'
// import IconRest1 from '../icons/IconRest1.vue'
// import IconGames from '../icons/IconGames.vue'
// import IconParamedic from '../icons/IconParamedic.vue'
// import IconDining from '../icons/IconDining.vue'
// import IconMedic from '../icons/IconMedic.vue'
// import IconMenRoom from '../icons/IconMenRoom.vue'
// import IconInformation from '../icons/IconInformation.vue'
// import IconDetoxification from '../icons/IconDetoxification.vue'
// import IconSpiritual from '../icons/IconSpiritual.vue'
// import IconGym from '../icons/IconGym.vue'
// import IconOrchard from '../icons/IconOrchard.vue'
// import IconPsico from '../icons/IconPsico.vue'
// import IconArt from '../icons/IconArt.vue'
// import IconTennis from '../icons/IconTennis.vue'
// import IconVoleyball from '../icons/IconVoleyball.vue'
// import IconWomenRoom from '../icons/IconWomenRoom.vue'

export default defineComponent({
  name: 'Guide',

  components: {
    SiteSection,
    SiteShapeTree,
    // IconRest1,
    // IconGames,
    // IconParamedic,
    // IconDining,
    // IconMedic,
    // IconMenRoom,
    // IconInformation,
    // IconDetoxification,
    // IconSpiritual,
    // IconGym,
    // IconOrchard,
    // IconPsico,
    // IconArt,
    // IconTennis,
    // IconVoleyball,
    // IconWomenRoom,
    AmenityCard,
  },

  setup() {
    // const swiper = ref<InstanceType<typeof Swiper> | null>(null)

    // const swiperOptions = computed(() => ({
    //   slidesPerView: 'auto',
    //   navigation: {
    //     nextEl: '.swiper-button-next',
    //     prevEl: '.swiper-button-prev',
    //   },
    // }))

    return {}
  },
})
