








































































































































































































import SiteSection from '../SiteSection.vue'
import SiteShapeTree from '@/components/SiteShapeTree.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Services',
  components: {
    SiteSection,
    SiteShapeTree,
  },
})
